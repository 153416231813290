import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Eb2BSearchV2QueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.SearchInput>;
}>;


export type Eb2BSearchV2Query = { __typename?: 'Query', searchV2: { __typename?: 'SearchResponseV2', count: number, products: Array<{ __typename?: 'ListingProductV2', id: number, name: string, image: string, brand: string, description?: string | null, notification: boolean, pillInfo?: { __typename?: 'PillPromotionInfo', pillType: Types.PillType, text: string, promotionId?: number | null } | null, category: { __typename?: 'ListingCategory', id: number, name: string, image?: string | null }, displays: Array<{ __typename?: 'ListingDisplayV2', id: number, default: boolean, units: number, storeUnavailabiliy: boolean, price: { __typename?: 'Money', amount: number, currency: string, scale: number }, unitPrice: { __typename?: 'Money', amount: number, currency: string, scale: number } }> }>, facets: { __typename?: 'Facets', categories?: Array<{ __typename?: 'ImageNameFacet', name: string, image?: string | null, count: number }> | null, brands?: Array<{ __typename?: 'NameFacet', name: string, count: number }> | null, subcategories?: Array<{ __typename?: 'SubcategoryFacet', name: string, displayName: string, count: number }> | null } } };


export const Eb2BSearchV2Document = gql`
    query EB2BSearchV2($input: SearchInput) {
  searchV2(input: $input) {
    products {
      id
      name
      image
      brand
      description
      notification
      pillInfo {
        pillType
        text
        promotionId
      }
      category {
        id
        name
        image
      }
      displays {
        id
        default
        units
        price {
          amount
          currency
          scale
        }
        unitPrice {
          amount
          currency
          scale
        }
        storeUnavailabiliy
      }
    }
    facets {
      categories {
        name
        image
        count
      }
      brands {
        name
        count
      }
      subcategories {
        name
        displayName
        count
      }
    }
    count
  }
}
    `;

/**
 * __useEb2BSearchV2Query__
 *
 * To run a query within a React component, call `useEb2BSearchV2Query` and pass it any options that fit your needs.
 * When your component renders, `useEb2BSearchV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEb2BSearchV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEb2BSearchV2Query(baseOptions?: Apollo.QueryHookOptions<Eb2BSearchV2Query, Eb2BSearchV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Eb2BSearchV2Query, Eb2BSearchV2QueryVariables>(Eb2BSearchV2Document, options);
      }
export function useEb2BSearchV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Eb2BSearchV2Query, Eb2BSearchV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Eb2BSearchV2Query, Eb2BSearchV2QueryVariables>(Eb2BSearchV2Document, options);
        }
export type Eb2BSearchV2QueryHookResult = ReturnType<typeof useEb2BSearchV2Query>;
export type Eb2BSearchV2LazyQueryHookResult = ReturnType<typeof useEb2BSearchV2LazyQuery>;
export type Eb2BSearchV2QueryResult = Apollo.QueryResult<Eb2BSearchV2Query, Eb2BSearchV2QueryVariables>;