import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindPromotionGroupsQueryVariables = Types.Exact<{
  input: Types.FindPromotionGroupsInput;
}>;


export type FindPromotionGroupsQuery = { __typename?: 'Query', findPromotionGroups: { __typename?: 'PagedPromotionGroup', promotionGroups: Array<{ __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean, stores?: Array<{ __typename?: 'Store', id: number } | null> | null, promotions?: Array<{ __typename?: 'Promotion', id: number } | null> | null } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindOnePromotionGroupQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type FindOnePromotionGroupQuery = { __typename?: 'Query', findPromotionGroupById?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null };

export type FindStoresInPromotionGroupQueryVariables = Types.Exact<{
  input: Types.FindStoresInPromotionGroupInput;
}>;


export type FindStoresInPromotionGroupQuery = { __typename?: 'Query', findStoresInPromotionGroup: { __typename?: 'PagedStoresInGroup', stores: Array<{ __typename?: 'PagedStoreInPromotionGroup', id: number, internalCode: string } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindPromotionsInPromotionGroupQueryVariables = Types.Exact<{
  input: Types.FindPromotionsInPromotionGroupInput;
}>;


export type FindPromotionsInPromotionGroupQuery = { __typename?: 'Query', findPromotionsInPromotionGroup: { __typename?: 'PagedPromotionsInGroup', promotions: Array<{ __typename?: 'PagedPromotionInPromotionGroup', internalCode: string } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type CreatePromotionGroupMutationVariables = Types.Exact<{
  input: Types.CreatePromotionGroupInput;
}>;


export type CreatePromotionGroupMutation = { __typename?: 'Mutation', createPromotionGroup: { __typename?: 'MutationPromotionGroupResponse', promotionGroup?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type UpdatePromotionGroupMutationVariables = Types.Exact<{
  input: Types.UpdatePromotionGroupInput;
}>;


export type UpdatePromotionGroupMutation = { __typename?: 'Mutation', updatePromotionGroup: { __typename?: 'MutationPromotionGroupResponse', promotionGroup?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type SetPromotionGroupStatusMutationVariables = Types.Exact<{
  input: Types.SetPromotionGroupStatusInput;
}>;


export type SetPromotionGroupStatusMutation = { __typename?: 'Mutation', setPromotionGroupStatus: { __typename?: 'MutationPromotionGroupResponse', promotionGroup?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type AddStoreToPromotionGroupMutationVariables = Types.Exact<{
  input: Types.AddStoreToPromotionGroupInput;
}>;


export type AddStoreToPromotionGroupMutation = { __typename?: 'Mutation', addStoreToPromotionGroup: { __typename?: 'MutationPromotionGroupResponse', promotionGroup?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type RemoveStoreFromPromotionGroupMutationVariables = Types.Exact<{
  input: Types.RemoveStoreFromPromotionGroupInput;
}>;


export type RemoveStoreFromPromotionGroupMutation = { __typename?: 'Mutation', removeStoreFromPromotionGroup: { __typename?: 'MutationPromotionGroupResponse', promotionGroup?: { __typename?: 'PromotionGroup', id: number, name: string, internalCode: string, enabled: boolean } | null } };

export type AddPromotionGroupToPromotionMutationVariables = Types.Exact<{
  input: Types.AddPromotionGroupToPromotionInput;
}>;


export type AddPromotionGroupToPromotionMutation = { __typename?: 'Mutation', addPromotionGroupToPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, internalCode: string, enabled: boolean } } };

export type RemovePromotionGroupFromPromotionMutationVariables = Types.Exact<{
  input: Types.RemovePromotionGroupFromPromotionInput;
}>;


export type RemovePromotionGroupFromPromotionMutation = { __typename?: 'Mutation', removePromotionGroupFromPromotion: { __typename?: 'MutationPromotionResponse', promotion: { __typename?: 'Promotion', id: number, name: string, internalCode: string, enabled: boolean } } };


export const FindPromotionGroupsDocument = gql`
    query FindPromotionGroups($input: FindPromotionGroupsInput!) {
  findPromotionGroups(findPromotionGroupsInput: $input) {
    promotionGroups {
      id
      name
      internalCode
      enabled
      stores {
        id
      }
      promotions {
        id
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindPromotionGroupsQuery__
 *
 * To run a query within a React component, call `useFindPromotionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionGroupsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionGroupsQuery(baseOptions: Apollo.QueryHookOptions<FindPromotionGroupsQuery, FindPromotionGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionGroupsQuery, FindPromotionGroupsQueryVariables>(FindPromotionGroupsDocument, options);
      }
export function useFindPromotionGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionGroupsQuery, FindPromotionGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionGroupsQuery, FindPromotionGroupsQueryVariables>(FindPromotionGroupsDocument, options);
        }
export type FindPromotionGroupsQueryHookResult = ReturnType<typeof useFindPromotionGroupsQuery>;
export type FindPromotionGroupsLazyQueryHookResult = ReturnType<typeof useFindPromotionGroupsLazyQuery>;
export type FindPromotionGroupsQueryResult = Apollo.QueryResult<FindPromotionGroupsQuery, FindPromotionGroupsQueryVariables>;
export const FindOnePromotionGroupDocument = gql`
    query FindOnePromotionGroup($id: Int!) {
  findPromotionGroupById(id: $id) {
    id
    name
    internalCode
    enabled
  }
}
    `;

/**
 * __useFindOnePromotionGroupQuery__
 *
 * To run a query within a React component, call `useFindOnePromotionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOnePromotionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOnePromotionGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOnePromotionGroupQuery(baseOptions: Apollo.QueryHookOptions<FindOnePromotionGroupQuery, FindOnePromotionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOnePromotionGroupQuery, FindOnePromotionGroupQueryVariables>(FindOnePromotionGroupDocument, options);
      }
export function useFindOnePromotionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOnePromotionGroupQuery, FindOnePromotionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOnePromotionGroupQuery, FindOnePromotionGroupQueryVariables>(FindOnePromotionGroupDocument, options);
        }
export type FindOnePromotionGroupQueryHookResult = ReturnType<typeof useFindOnePromotionGroupQuery>;
export type FindOnePromotionGroupLazyQueryHookResult = ReturnType<typeof useFindOnePromotionGroupLazyQuery>;
export type FindOnePromotionGroupQueryResult = Apollo.QueryResult<FindOnePromotionGroupQuery, FindOnePromotionGroupQueryVariables>;
export const FindStoresInPromotionGroupDocument = gql`
    query FindStoresInPromotionGroup($input: FindStoresInPromotionGroupInput!) {
  findStoresInPromotionGroup(findStoresInPromotionGroupInput: $input) {
    stores {
      id
      internalCode
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindStoresInPromotionGroupQuery__
 *
 * To run a query within a React component, call `useFindStoresInPromotionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStoresInPromotionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStoresInPromotionGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindStoresInPromotionGroupQuery(baseOptions: Apollo.QueryHookOptions<FindStoresInPromotionGroupQuery, FindStoresInPromotionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStoresInPromotionGroupQuery, FindStoresInPromotionGroupQueryVariables>(FindStoresInPromotionGroupDocument, options);
      }
export function useFindStoresInPromotionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStoresInPromotionGroupQuery, FindStoresInPromotionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStoresInPromotionGroupQuery, FindStoresInPromotionGroupQueryVariables>(FindStoresInPromotionGroupDocument, options);
        }
export type FindStoresInPromotionGroupQueryHookResult = ReturnType<typeof useFindStoresInPromotionGroupQuery>;
export type FindStoresInPromotionGroupLazyQueryHookResult = ReturnType<typeof useFindStoresInPromotionGroupLazyQuery>;
export type FindStoresInPromotionGroupQueryResult = Apollo.QueryResult<FindStoresInPromotionGroupQuery, FindStoresInPromotionGroupQueryVariables>;
export const FindPromotionsInPromotionGroupDocument = gql`
    query FindPromotionsInPromotionGroup($input: FindPromotionsInPromotionGroupInput!) {
  findPromotionsInPromotionGroup(findPromotionsInPromotionGroupInput: $input) {
    promotions {
      internalCode
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindPromotionsInPromotionGroupQuery__
 *
 * To run a query within a React component, call `useFindPromotionsInPromotionGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPromotionsInPromotionGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPromotionsInPromotionGroupQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindPromotionsInPromotionGroupQuery(baseOptions: Apollo.QueryHookOptions<FindPromotionsInPromotionGroupQuery, FindPromotionsInPromotionGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPromotionsInPromotionGroupQuery, FindPromotionsInPromotionGroupQueryVariables>(FindPromotionsInPromotionGroupDocument, options);
      }
export function useFindPromotionsInPromotionGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPromotionsInPromotionGroupQuery, FindPromotionsInPromotionGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPromotionsInPromotionGroupQuery, FindPromotionsInPromotionGroupQueryVariables>(FindPromotionsInPromotionGroupDocument, options);
        }
export type FindPromotionsInPromotionGroupQueryHookResult = ReturnType<typeof useFindPromotionsInPromotionGroupQuery>;
export type FindPromotionsInPromotionGroupLazyQueryHookResult = ReturnType<typeof useFindPromotionsInPromotionGroupLazyQuery>;
export type FindPromotionsInPromotionGroupQueryResult = Apollo.QueryResult<FindPromotionsInPromotionGroupQuery, FindPromotionsInPromotionGroupQueryVariables>;
export const CreatePromotionGroupDocument = gql`
    mutation CreatePromotionGroup($input: CreatePromotionGroupInput!) {
  createPromotionGroup(createPromotionGroupInput: $input) {
    promotionGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type CreatePromotionGroupMutationFn = Apollo.MutationFunction<CreatePromotionGroupMutation, CreatePromotionGroupMutationVariables>;

/**
 * __useCreatePromotionGroupMutation__
 *
 * To run a mutation, you first call `useCreatePromotionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromotionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromotionGroupMutation, { data, loading, error }] = useCreatePromotionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePromotionGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromotionGroupMutation, CreatePromotionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromotionGroupMutation, CreatePromotionGroupMutationVariables>(CreatePromotionGroupDocument, options);
      }
export type CreatePromotionGroupMutationHookResult = ReturnType<typeof useCreatePromotionGroupMutation>;
export type CreatePromotionGroupMutationResult = Apollo.MutationResult<CreatePromotionGroupMutation>;
export type CreatePromotionGroupMutationOptions = Apollo.BaseMutationOptions<CreatePromotionGroupMutation, CreatePromotionGroupMutationVariables>;
export const UpdatePromotionGroupDocument = gql`
    mutation UpdatePromotionGroup($input: UpdatePromotionGroupInput!) {
  updatePromotionGroup(updatePromotionGroupInput: $input) {
    promotionGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type UpdatePromotionGroupMutationFn = Apollo.MutationFunction<UpdatePromotionGroupMutation, UpdatePromotionGroupMutationVariables>;

/**
 * __useUpdatePromotionGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePromotionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromotionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromotionGroupMutation, { data, loading, error }] = useUpdatePromotionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePromotionGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromotionGroupMutation, UpdatePromotionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromotionGroupMutation, UpdatePromotionGroupMutationVariables>(UpdatePromotionGroupDocument, options);
      }
export type UpdatePromotionGroupMutationHookResult = ReturnType<typeof useUpdatePromotionGroupMutation>;
export type UpdatePromotionGroupMutationResult = Apollo.MutationResult<UpdatePromotionGroupMutation>;
export type UpdatePromotionGroupMutationOptions = Apollo.BaseMutationOptions<UpdatePromotionGroupMutation, UpdatePromotionGroupMutationVariables>;
export const SetPromotionGroupStatusDocument = gql`
    mutation SetPromotionGroupStatus($input: SetPromotionGroupStatusInput!) {
  setPromotionGroupStatus(setPromotionGroupStatusInput: $input) {
    promotionGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type SetPromotionGroupStatusMutationFn = Apollo.MutationFunction<SetPromotionGroupStatusMutation, SetPromotionGroupStatusMutationVariables>;

/**
 * __useSetPromotionGroupStatusMutation__
 *
 * To run a mutation, you first call `useSetPromotionGroupStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPromotionGroupStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPromotionGroupStatusMutation, { data, loading, error }] = useSetPromotionGroupStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPromotionGroupStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetPromotionGroupStatusMutation, SetPromotionGroupStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPromotionGroupStatusMutation, SetPromotionGroupStatusMutationVariables>(SetPromotionGroupStatusDocument, options);
      }
export type SetPromotionGroupStatusMutationHookResult = ReturnType<typeof useSetPromotionGroupStatusMutation>;
export type SetPromotionGroupStatusMutationResult = Apollo.MutationResult<SetPromotionGroupStatusMutation>;
export type SetPromotionGroupStatusMutationOptions = Apollo.BaseMutationOptions<SetPromotionGroupStatusMutation, SetPromotionGroupStatusMutationVariables>;
export const AddStoreToPromotionGroupDocument = gql`
    mutation AddStoreToPromotionGroup($input: AddStoreToPromotionGroupInput!) {
  addStoreToPromotionGroup(addStoreToPromotionGroupInput: $input) {
    promotionGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type AddStoreToPromotionGroupMutationFn = Apollo.MutationFunction<AddStoreToPromotionGroupMutation, AddStoreToPromotionGroupMutationVariables>;

/**
 * __useAddStoreToPromotionGroupMutation__
 *
 * To run a mutation, you first call `useAddStoreToPromotionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreToPromotionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreToPromotionGroupMutation, { data, loading, error }] = useAddStoreToPromotionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddStoreToPromotionGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreToPromotionGroupMutation, AddStoreToPromotionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreToPromotionGroupMutation, AddStoreToPromotionGroupMutationVariables>(AddStoreToPromotionGroupDocument, options);
      }
export type AddStoreToPromotionGroupMutationHookResult = ReturnType<typeof useAddStoreToPromotionGroupMutation>;
export type AddStoreToPromotionGroupMutationResult = Apollo.MutationResult<AddStoreToPromotionGroupMutation>;
export type AddStoreToPromotionGroupMutationOptions = Apollo.BaseMutationOptions<AddStoreToPromotionGroupMutation, AddStoreToPromotionGroupMutationVariables>;
export const RemoveStoreFromPromotionGroupDocument = gql`
    mutation RemoveStoreFromPromotionGroup($input: RemoveStoreFromPromotionGroupInput!) {
  removeStoreFromPromotionGroup(removeStoreFromPromotionGroupInput: $input) {
    promotionGroup {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type RemoveStoreFromPromotionGroupMutationFn = Apollo.MutationFunction<RemoveStoreFromPromotionGroupMutation, RemoveStoreFromPromotionGroupMutationVariables>;

/**
 * __useRemoveStoreFromPromotionGroupMutation__
 *
 * To run a mutation, you first call `useRemoveStoreFromPromotionGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreFromPromotionGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreFromPromotionGroupMutation, { data, loading, error }] = useRemoveStoreFromPromotionGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStoreFromPromotionGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreFromPromotionGroupMutation, RemoveStoreFromPromotionGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreFromPromotionGroupMutation, RemoveStoreFromPromotionGroupMutationVariables>(RemoveStoreFromPromotionGroupDocument, options);
      }
export type RemoveStoreFromPromotionGroupMutationHookResult = ReturnType<typeof useRemoveStoreFromPromotionGroupMutation>;
export type RemoveStoreFromPromotionGroupMutationResult = Apollo.MutationResult<RemoveStoreFromPromotionGroupMutation>;
export type RemoveStoreFromPromotionGroupMutationOptions = Apollo.BaseMutationOptions<RemoveStoreFromPromotionGroupMutation, RemoveStoreFromPromotionGroupMutationVariables>;
export const AddPromotionGroupToPromotionDocument = gql`
    mutation AddPromotionGroupToPromotion($input: AddPromotionGroupToPromotionInput!) {
  addPromotionGroupToPromotion(addPromotionGroupToPromotionInput: $input) {
    promotion {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type AddPromotionGroupToPromotionMutationFn = Apollo.MutationFunction<AddPromotionGroupToPromotionMutation, AddPromotionGroupToPromotionMutationVariables>;

/**
 * __useAddPromotionGroupToPromotionMutation__
 *
 * To run a mutation, you first call `useAddPromotionGroupToPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPromotionGroupToPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPromotionGroupToPromotionMutation, { data, loading, error }] = useAddPromotionGroupToPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPromotionGroupToPromotionMutation(baseOptions?: Apollo.MutationHookOptions<AddPromotionGroupToPromotionMutation, AddPromotionGroupToPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPromotionGroupToPromotionMutation, AddPromotionGroupToPromotionMutationVariables>(AddPromotionGroupToPromotionDocument, options);
      }
export type AddPromotionGroupToPromotionMutationHookResult = ReturnType<typeof useAddPromotionGroupToPromotionMutation>;
export type AddPromotionGroupToPromotionMutationResult = Apollo.MutationResult<AddPromotionGroupToPromotionMutation>;
export type AddPromotionGroupToPromotionMutationOptions = Apollo.BaseMutationOptions<AddPromotionGroupToPromotionMutation, AddPromotionGroupToPromotionMutationVariables>;
export const RemovePromotionGroupFromPromotionDocument = gql`
    mutation RemovePromotionGroupFromPromotion($input: RemovePromotionGroupFromPromotionInput!) {
  removePromotionGroupFromPromotion(
    removePromotionGroupFromPromotionInput: $input
  ) {
    promotion {
      id
      name
      internalCode
      enabled
    }
  }
}
    `;
export type RemovePromotionGroupFromPromotionMutationFn = Apollo.MutationFunction<RemovePromotionGroupFromPromotionMutation, RemovePromotionGroupFromPromotionMutationVariables>;

/**
 * __useRemovePromotionGroupFromPromotionMutation__
 *
 * To run a mutation, you first call `useRemovePromotionGroupFromPromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePromotionGroupFromPromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePromotionGroupFromPromotionMutation, { data, loading, error }] = useRemovePromotionGroupFromPromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePromotionGroupFromPromotionMutation(baseOptions?: Apollo.MutationHookOptions<RemovePromotionGroupFromPromotionMutation, RemovePromotionGroupFromPromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePromotionGroupFromPromotionMutation, RemovePromotionGroupFromPromotionMutationVariables>(RemovePromotionGroupFromPromotionDocument, options);
      }
export type RemovePromotionGroupFromPromotionMutationHookResult = ReturnType<typeof useRemovePromotionGroupFromPromotionMutation>;
export type RemovePromotionGroupFromPromotionMutationResult = Apollo.MutationResult<RemovePromotionGroupFromPromotionMutation>;
export type RemovePromotionGroupFromPromotionMutationOptions = Apollo.BaseMutationOptions<RemovePromotionGroupFromPromotionMutation, RemovePromotionGroupFromPromotionMutationVariables>;