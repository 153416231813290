import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FindAllStoresQueryVariables = Types.Exact<{
  findAllStoresInput: Types.FindAllStoresInput;
}>;


export type FindAllStoresQuery = { __typename?: 'Query', findAllStores: { __typename?: 'PagedStore', stores: Array<{ __typename?: 'Store', addressFormat?: string | null, id: number, supplierId: number, legalName: string, legalId: string, ownerName: string, ownerIdentifier?: string | null, internalCode?: string | null, enabled: boolean, addresses?: Array<{ __typename?: 'Address', id: number, addressLine: string, apartmentNumber?: string | null, zipCode?: string | null }> | null, users?: Array<{ __typename?: 'User', id: number, userType: Types.UserType, name?: string | null, status: Types.UserStatusType } | null> | null } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type FindAllStoresForSalesRepQueryVariables = Types.Exact<{
  findAllStoresInput: Types.FindAllStoresInput;
}>;


export type FindAllStoresForSalesRepQuery = { __typename?: 'Query', findAllStoresForSalesRep: { __typename?: 'PagedStore', stores: Array<{ __typename?: 'Store', id: number, internalCode?: string | null, legalId: string } | null>, pagination: { __typename?: 'PageInfo', pageBased?: { __typename?: 'PageBasedInfo', count: number } | null } } };

export type CreateStoreMutationVariables = Types.Exact<{
  createStoreInput: Types.CreateStoreInput;
}>;


export type CreateStoreMutation = { __typename?: 'Mutation', createStore: { __typename?: 'MutationStoreResponse', store?: { __typename?: 'Store', id: number, latitude?: string | null, supplierId: number, addressFormat?: string | null, legalName: string, legalId: string, ownerName: string, ownerIdentifier?: string | null } | null } };

export type FindOneStoreQueryVariables = Types.Exact<{
  findOneStoreId: Types.Scalars['Int'];
}>;


export type FindOneStoreQuery = { __typename?: 'Query', findOneStore?: { __typename?: 'Store', id: number, addressLine1: string, addressLine2?: string | null, zipCode?: string | null, latitude?: string | null, longitude?: string | null, addressFormat?: string | null, legalName: string, legalId: string, ownerName: string, ownerIdentifier?: string | null, internalCode?: string | null, storeSettings?: { __typename?: 'StoreSettings', id?: number | null, supportContactEmail?: string | null, supportContactPhone?: string | null } | null, addresses?: Array<{ __typename?: 'Address', id: number, addressLine: string, apartmentNumber?: string | null, zipCode?: string | null }> | null } | null };

export type UpdateStoreMutationVariables = Types.Exact<{
  updateStoreInput: Types.UpdateStoreInput;
}>;


export type UpdateStoreMutation = { __typename?: 'Mutation', updateStore: { __typename?: 'MutationStoreResponse', store?: { __typename?: 'Store', id: number, addressFormat?: string | null, legalName: string, legalId: string, ownerName: string, ownerIdentifier?: string | null } | null } };

export type FindAllUsersStoreQueryVariables = Types.Exact<{
  findAllUsersStoreInput: Types.FindAllUsersStoreInput;
}>;


export type FindAllUsersStoreQuery = { __typename?: 'Query', findAllUsersStore: Array<{ __typename?: 'User', id: number, sub: string, userType: Types.UserType, username: string, name?: string | null, loginMethod: Types.LoginMethodType, status: Types.UserStatusType, supplierId: number, countryCode?: string | null, phone?: string | null } | null> };

export type CreatePhoneUserStoreMutationVariables = Types.Exact<{
  createPhoneUserStoreInput: Types.CreatePhoneUserStoreInput;
}>;


export type CreatePhoneUserStoreMutation = { __typename?: 'Mutation', createPhoneUserStore: { __typename?: 'MutationUserStoreResponse', storeUserCreatedResponse?: { __typename?: 'StoreUserCreatedResponse', id: number, username: string, name?: string | null, status: Types.UserStatusType } | null } };

export type CreateEmailUserStoreMutationVariables = Types.Exact<{
  createEmailUserStoreInput: Types.CreateEmailUserStoreInput;
}>;


export type CreateEmailUserStoreMutation = { __typename?: 'Mutation', createEmailUserStore: { __typename?: 'MutationUserStoreResponse', storeUserCreatedResponse?: { __typename?: 'StoreUserCreatedResponse', id: number, username: string, name?: string | null, status: Types.UserStatusType } | null } };

export type UpdateUserStoreMutationVariables = Types.Exact<{
  updateUserStoreInput: Types.UpdateUserStoreInput;
}>;


export type UpdateUserStoreMutation = { __typename?: 'Mutation', updateUserStore: { __typename?: 'MutationUserStoreResponse', storeUserCreatedResponse?: { __typename?: 'StoreUserCreatedResponse', id: number, username: string, name?: string | null, status: Types.UserStatusType } | null } };

export type DeleteUserStoreMutationVariables = Types.Exact<{
  deleteUserStoreInput: Types.DeleteUserStoreInput;
}>;


export type DeleteUserStoreMutation = { __typename?: 'Mutation', deleteUserStore: { __typename?: 'MutationUserStoreResponse', storeUserCreatedResponse?: { __typename?: 'StoreUserCreatedResponse', id: number, username: string, name?: string | null, status: Types.UserStatusType } | null } };

export type ChangeStoreStatusMutationVariables = Types.Exact<{
  changeStoreStatusInput: Types.ChangeStoreStatusInput;
}>;


export type ChangeStoreStatusMutation = { __typename?: 'Mutation', changeStoreStatus: { __typename?: 'MutationStoreResponse', store?: { __typename?: 'Store', id: number, enabled: boolean, legalId: string, legalName: string } | null } };

export type AddInternalCodeStoreMutationVariables = Types.Exact<{
  addInternalCodeStoreInput: Types.AddInternalCodeStoreInput;
}>;


export type AddInternalCodeStoreMutation = { __typename?: 'Mutation', addInternalCodeStore?: { __typename?: 'MutationStoreResponse', store?: { __typename?: 'Store', id: number, internalCode?: string | null, enabled: boolean } | null } | null };


export const FindAllStoresDocument = gql`
    query FindAllStores($findAllStoresInput: FindAllStoresInput!) {
  findAllStores(findAllStoresInput: $findAllStoresInput) {
    stores {
      addressFormat
      id
      supplierId
      addresses {
        id
        addressLine
        apartmentNumber
        zipCode
      }
      legalName
      legalId
      ownerName
      ownerIdentifier
      internalCode
      enabled
      users {
        id
        userType
        name
        status
      }
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindAllStoresQuery__
 *
 * To run a query within a React component, call `useFindAllStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllStoresQuery({
 *   variables: {
 *      findAllStoresInput: // value for 'findAllStoresInput'
 *   },
 * });
 */
export function useFindAllStoresQuery(baseOptions: Apollo.QueryHookOptions<FindAllStoresQuery, FindAllStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllStoresQuery, FindAllStoresQueryVariables>(FindAllStoresDocument, options);
      }
export function useFindAllStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllStoresQuery, FindAllStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllStoresQuery, FindAllStoresQueryVariables>(FindAllStoresDocument, options);
        }
export type FindAllStoresQueryHookResult = ReturnType<typeof useFindAllStoresQuery>;
export type FindAllStoresLazyQueryHookResult = ReturnType<typeof useFindAllStoresLazyQuery>;
export type FindAllStoresQueryResult = Apollo.QueryResult<FindAllStoresQuery, FindAllStoresQueryVariables>;
export const FindAllStoresForSalesRepDocument = gql`
    query findAllStoresForSalesRep($findAllStoresInput: FindAllStoresInput!) {
  findAllStoresForSalesRep(findAllStoresInput: $findAllStoresInput) {
    stores {
      id
      internalCode
      legalId
    }
    pagination {
      pageBased {
        count
      }
    }
  }
}
    `;

/**
 * __useFindAllStoresForSalesRepQuery__
 *
 * To run a query within a React component, call `useFindAllStoresForSalesRepQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllStoresForSalesRepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllStoresForSalesRepQuery({
 *   variables: {
 *      findAllStoresInput: // value for 'findAllStoresInput'
 *   },
 * });
 */
export function useFindAllStoresForSalesRepQuery(baseOptions: Apollo.QueryHookOptions<FindAllStoresForSalesRepQuery, FindAllStoresForSalesRepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllStoresForSalesRepQuery, FindAllStoresForSalesRepQueryVariables>(FindAllStoresForSalesRepDocument, options);
      }
export function useFindAllStoresForSalesRepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllStoresForSalesRepQuery, FindAllStoresForSalesRepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllStoresForSalesRepQuery, FindAllStoresForSalesRepQueryVariables>(FindAllStoresForSalesRepDocument, options);
        }
export type FindAllStoresForSalesRepQueryHookResult = ReturnType<typeof useFindAllStoresForSalesRepQuery>;
export type FindAllStoresForSalesRepLazyQueryHookResult = ReturnType<typeof useFindAllStoresForSalesRepLazyQuery>;
export type FindAllStoresForSalesRepQueryResult = Apollo.QueryResult<FindAllStoresForSalesRepQuery, FindAllStoresForSalesRepQueryVariables>;
export const CreateStoreDocument = gql`
    mutation CreateStore($createStoreInput: CreateStoreInput!) {
  createStore(createStoreInput: $createStoreInput) {
    store {
      id
      latitude
      supplierId
      addressFormat
      legalName
      legalId
      ownerName
      ownerIdentifier
    }
  }
}
    `;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      createStoreInput: // value for 'createStoreInput'
 *   },
 * });
 */
export function useCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, options);
      }
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const FindOneStoreDocument = gql`
    query FindOneStore($findOneStoreId: Int!) {
  findOneStore(id: $findOneStoreId) {
    id
    addressLine1
    addressLine2
    zipCode
    latitude
    longitude
    addressFormat
    legalName
    legalId
    ownerName
    ownerIdentifier
    internalCode
    storeSettings {
      id
      supportContactEmail
      supportContactPhone
    }
    addresses {
      id
      addressLine
      apartmentNumber
      zipCode
    }
  }
}
    `;

/**
 * __useFindOneStoreQuery__
 *
 * To run a query within a React component, call `useFindOneStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneStoreQuery({
 *   variables: {
 *      findOneStoreId: // value for 'findOneStoreId'
 *   },
 * });
 */
export function useFindOneStoreQuery(baseOptions: Apollo.QueryHookOptions<FindOneStoreQuery, FindOneStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneStoreQuery, FindOneStoreQueryVariables>(FindOneStoreDocument, options);
      }
export function useFindOneStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneStoreQuery, FindOneStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneStoreQuery, FindOneStoreQueryVariables>(FindOneStoreDocument, options);
        }
export type FindOneStoreQueryHookResult = ReturnType<typeof useFindOneStoreQuery>;
export type FindOneStoreLazyQueryHookResult = ReturnType<typeof useFindOneStoreLazyQuery>;
export type FindOneStoreQueryResult = Apollo.QueryResult<FindOneStoreQuery, FindOneStoreQueryVariables>;
export const UpdateStoreDocument = gql`
    mutation UpdateStore($updateStoreInput: UpdateStoreInput!) {
  updateStore(updateStoreInput: $updateStoreInput) {
    store {
      id
      addressFormat
      legalName
      legalId
      ownerName
      ownerIdentifier
    }
  }
}
    `;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      updateStoreInput: // value for 'updateStoreInput'
 *   },
 * });
 */
export function useUpdateStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, options);
      }
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const FindAllUsersStoreDocument = gql`
    query FindAllUsersStore($findAllUsersStoreInput: FindAllUsersStoreInput!) {
  findAllUsersStore(findAllUsersStoreInput: $findAllUsersStoreInput) {
    id
    sub
    userType
    username
    name
    loginMethod
    status
    supplierId
    countryCode
    phone
  }
}
    `;

/**
 * __useFindAllUsersStoreQuery__
 *
 * To run a query within a React component, call `useFindAllUsersStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersStoreQuery({
 *   variables: {
 *      findAllUsersStoreInput: // value for 'findAllUsersStoreInput'
 *   },
 * });
 */
export function useFindAllUsersStoreQuery(baseOptions: Apollo.QueryHookOptions<FindAllUsersStoreQuery, FindAllUsersStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllUsersStoreQuery, FindAllUsersStoreQueryVariables>(FindAllUsersStoreDocument, options);
      }
export function useFindAllUsersStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersStoreQuery, FindAllUsersStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllUsersStoreQuery, FindAllUsersStoreQueryVariables>(FindAllUsersStoreDocument, options);
        }
export type FindAllUsersStoreQueryHookResult = ReturnType<typeof useFindAllUsersStoreQuery>;
export type FindAllUsersStoreLazyQueryHookResult = ReturnType<typeof useFindAllUsersStoreLazyQuery>;
export type FindAllUsersStoreQueryResult = Apollo.QueryResult<FindAllUsersStoreQuery, FindAllUsersStoreQueryVariables>;
export const CreatePhoneUserStoreDocument = gql`
    mutation CreatePhoneUserStore($createPhoneUserStoreInput: CreatePhoneUserStoreInput!) {
  createPhoneUserStore(createPhoneUserStoreInput: $createPhoneUserStoreInput) {
    storeUserCreatedResponse {
      id
      username
      name
      status
    }
  }
}
    `;
export type CreatePhoneUserStoreMutationFn = Apollo.MutationFunction<CreatePhoneUserStoreMutation, CreatePhoneUserStoreMutationVariables>;

/**
 * __useCreatePhoneUserStoreMutation__
 *
 * To run a mutation, you first call `useCreatePhoneUserStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhoneUserStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhoneUserStoreMutation, { data, loading, error }] = useCreatePhoneUserStoreMutation({
 *   variables: {
 *      createPhoneUserStoreInput: // value for 'createPhoneUserStoreInput'
 *   },
 * });
 */
export function useCreatePhoneUserStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhoneUserStoreMutation, CreatePhoneUserStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhoneUserStoreMutation, CreatePhoneUserStoreMutationVariables>(CreatePhoneUserStoreDocument, options);
      }
export type CreatePhoneUserStoreMutationHookResult = ReturnType<typeof useCreatePhoneUserStoreMutation>;
export type CreatePhoneUserStoreMutationResult = Apollo.MutationResult<CreatePhoneUserStoreMutation>;
export type CreatePhoneUserStoreMutationOptions = Apollo.BaseMutationOptions<CreatePhoneUserStoreMutation, CreatePhoneUserStoreMutationVariables>;
export const CreateEmailUserStoreDocument = gql`
    mutation CreateEmailUserStore($createEmailUserStoreInput: CreateEmailUserStoreInput!) {
  createEmailUserStore(createEmailUserStoreInput: $createEmailUserStoreInput) {
    storeUserCreatedResponse {
      id
      username
      name
      status
    }
  }
}
    `;
export type CreateEmailUserStoreMutationFn = Apollo.MutationFunction<CreateEmailUserStoreMutation, CreateEmailUserStoreMutationVariables>;

/**
 * __useCreateEmailUserStoreMutation__
 *
 * To run a mutation, you first call `useCreateEmailUserStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailUserStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailUserStoreMutation, { data, loading, error }] = useCreateEmailUserStoreMutation({
 *   variables: {
 *      createEmailUserStoreInput: // value for 'createEmailUserStoreInput'
 *   },
 * });
 */
export function useCreateEmailUserStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailUserStoreMutation, CreateEmailUserStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailUserStoreMutation, CreateEmailUserStoreMutationVariables>(CreateEmailUserStoreDocument, options);
      }
export type CreateEmailUserStoreMutationHookResult = ReturnType<typeof useCreateEmailUserStoreMutation>;
export type CreateEmailUserStoreMutationResult = Apollo.MutationResult<CreateEmailUserStoreMutation>;
export type CreateEmailUserStoreMutationOptions = Apollo.BaseMutationOptions<CreateEmailUserStoreMutation, CreateEmailUserStoreMutationVariables>;
export const UpdateUserStoreDocument = gql`
    mutation UpdateUserStore($updateUserStoreInput: UpdateUserStoreInput!) {
  updateUserStore(updateUserStoreInput: $updateUserStoreInput) {
    storeUserCreatedResponse {
      id
      username
      name
      status
    }
  }
}
    `;
export type UpdateUserStoreMutationFn = Apollo.MutationFunction<UpdateUserStoreMutation, UpdateUserStoreMutationVariables>;

/**
 * __useUpdateUserStoreMutation__
 *
 * To run a mutation, you first call `useUpdateUserStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStoreMutation, { data, loading, error }] = useUpdateUserStoreMutation({
 *   variables: {
 *      updateUserStoreInput: // value for 'updateUserStoreInput'
 *   },
 * });
 */
export function useUpdateUserStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStoreMutation, UpdateUserStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStoreMutation, UpdateUserStoreMutationVariables>(UpdateUserStoreDocument, options);
      }
export type UpdateUserStoreMutationHookResult = ReturnType<typeof useUpdateUserStoreMutation>;
export type UpdateUserStoreMutationResult = Apollo.MutationResult<UpdateUserStoreMutation>;
export type UpdateUserStoreMutationOptions = Apollo.BaseMutationOptions<UpdateUserStoreMutation, UpdateUserStoreMutationVariables>;
export const DeleteUserStoreDocument = gql`
    mutation DeleteUserStore($deleteUserStoreInput: DeleteUserStoreInput!) {
  deleteUserStore(deleteUserStoreInput: $deleteUserStoreInput) {
    storeUserCreatedResponse {
      id
      username
      name
      status
    }
  }
}
    `;
export type DeleteUserStoreMutationFn = Apollo.MutationFunction<DeleteUserStoreMutation, DeleteUserStoreMutationVariables>;

/**
 * __useDeleteUserStoreMutation__
 *
 * To run a mutation, you first call `useDeleteUserStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserStoreMutation, { data, loading, error }] = useDeleteUserStoreMutation({
 *   variables: {
 *      deleteUserStoreInput: // value for 'deleteUserStoreInput'
 *   },
 * });
 */
export function useDeleteUserStoreMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserStoreMutation, DeleteUserStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserStoreMutation, DeleteUserStoreMutationVariables>(DeleteUserStoreDocument, options);
      }
export type DeleteUserStoreMutationHookResult = ReturnType<typeof useDeleteUserStoreMutation>;
export type DeleteUserStoreMutationResult = Apollo.MutationResult<DeleteUserStoreMutation>;
export type DeleteUserStoreMutationOptions = Apollo.BaseMutationOptions<DeleteUserStoreMutation, DeleteUserStoreMutationVariables>;
export const ChangeStoreStatusDocument = gql`
    mutation ChangeStoreStatus($changeStoreStatusInput: ChangeStoreStatusInput!) {
  changeStoreStatus(changeStoreStatusInput: $changeStoreStatusInput) {
    store {
      id
      enabled
      legalId
      legalName
    }
  }
}
    `;
export type ChangeStoreStatusMutationFn = Apollo.MutationFunction<ChangeStoreStatusMutation, ChangeStoreStatusMutationVariables>;

/**
 * __useChangeStoreStatusMutation__
 *
 * To run a mutation, you first call `useChangeStoreStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStoreStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStoreStatusMutation, { data, loading, error }] = useChangeStoreStatusMutation({
 *   variables: {
 *      changeStoreStatusInput: // value for 'changeStoreStatusInput'
 *   },
 * });
 */
export function useChangeStoreStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeStoreStatusMutation, ChangeStoreStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeStoreStatusMutation, ChangeStoreStatusMutationVariables>(ChangeStoreStatusDocument, options);
      }
export type ChangeStoreStatusMutationHookResult = ReturnType<typeof useChangeStoreStatusMutation>;
export type ChangeStoreStatusMutationResult = Apollo.MutationResult<ChangeStoreStatusMutation>;
export type ChangeStoreStatusMutationOptions = Apollo.BaseMutationOptions<ChangeStoreStatusMutation, ChangeStoreStatusMutationVariables>;
export const AddInternalCodeStoreDocument = gql`
    mutation AddInternalCodeStore($addInternalCodeStoreInput: AddInternalCodeStoreInput!) {
  addInternalCodeStore(addInternalCodeStoreInput: $addInternalCodeStoreInput) {
    store {
      id
      internalCode
      enabled
    }
  }
}
    `;
export type AddInternalCodeStoreMutationFn = Apollo.MutationFunction<AddInternalCodeStoreMutation, AddInternalCodeStoreMutationVariables>;

/**
 * __useAddInternalCodeStoreMutation__
 *
 * To run a mutation, you first call `useAddInternalCodeStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInternalCodeStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInternalCodeStoreMutation, { data, loading, error }] = useAddInternalCodeStoreMutation({
 *   variables: {
 *      addInternalCodeStoreInput: // value for 'addInternalCodeStoreInput'
 *   },
 * });
 */
export function useAddInternalCodeStoreMutation(baseOptions?: Apollo.MutationHookOptions<AddInternalCodeStoreMutation, AddInternalCodeStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInternalCodeStoreMutation, AddInternalCodeStoreMutationVariables>(AddInternalCodeStoreDocument, options);
      }
export type AddInternalCodeStoreMutationHookResult = ReturnType<typeof useAddInternalCodeStoreMutation>;
export type AddInternalCodeStoreMutationResult = Apollo.MutationResult<AddInternalCodeStoreMutation>;
export type AddInternalCodeStoreMutationOptions = Apollo.BaseMutationOptions<AddInternalCodeStoreMutation, AddInternalCodeStoreMutationVariables>;